import * as React from "react";

const LoadingIndicator = () => {

    return <div className="text-center">
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>

}

export default LoadingIndicator;
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
//import * as React from 'react';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
//import * as ReactDOM from 'react-dom';
import LoadingIndicator from './components/LoadingIndicator';
const ChapterEventsCalendarWidget = lazy(() => import('./components/ChapterEventsCalendarWidget'));
const EventsListing = lazy(() => import('./components/EventsListing'));
const CorporateEventsListing = lazy(() => import('./components/CorporateEventsListing'));
const ChaptersListing = lazy(() => import('./components/ChaptersListing'));
const ChaptersBlogListingFilters = lazy(() => import('./components/ChaptersBlogListingFilters'));
const ChaptersBlogListingResult = lazy(() => import('./components/ChaptersBlogListingResult'));
const ChaptersListingRedirect = lazy(() => import('./components/ChaptersListingRedirect'));
const SavingsBenefitsListingRedirect = lazy(() => import('./components/SavingsBenefitsListingRedirect'));
const SavingsBenefitsListing = lazy(() => import('./components/SavingsBenefitsListing'));
const ChaptersBlogListingRedirect = lazy(() => import('./components/ChaptersBlogListingRedirect'));
const FinLitListing = lazy(() => import('./components/FinLitListing'));
const NewsListing = lazy(() => import('./components/NewsListing'));
const NVKBListing = lazy(() => import('./components/NVKBListing'));
const NVKBBrowse = lazy(() => import('./components/NVKBBrowse'));
const FeaturedChapter = lazy(() => import('./components/FeaturedChapter'));
const Spotlight = lazy(() => import('./components/Spotlight'));
const FormComponent = lazy(() => import('./components/formBuilder/formComponent'));
const SiteSearch = lazy(() => import('./components/SiteSearch'));

const globalAny: any = global;
globalAny.React = React;
globalAny.ReactDOM = ReactDOM;

globalAny.ChapterEventsCalendarWidget = (props) => (<Suspense fallback={<LoadingIndicator />}><ChapterEventsCalendarWidget {...props} /></Suspense>);
globalAny.CorporateEventsListing = (props) => (<Suspense fallback={<LoadingIndicator />}><CorporateEventsListing {...props} /></Suspense>);
globalAny.EventsListing = (props) => (<Suspense fallback={<LoadingIndicator />}><EventsListing {...props} /></Suspense>);
globalAny.ChaptersListing = (props) => (<Suspense fallback={<LoadingIndicator />}><ChaptersListing {...props} /></Suspense>);
globalAny.ChaptersBlogListingFilters = (props) => (<Suspense fallback={<LoadingIndicator />}><ChaptersBlogListingFilters {...props} /></Suspense>);
globalAny.ChaptersBlogListingResult = (props) => (<Suspense fallback={<LoadingIndicator />}><ChaptersBlogListingResult {...props} /></Suspense>);
globalAny.ChaptersListingRedirect = (props) => (<Suspense fallback={<LoadingIndicator />}><ChaptersListingRedirect {...props} /></Suspense>);
globalAny.SavingsBenefitsListingRedirect = (props) => (<Suspense fallback={<LoadingIndicator />}><SavingsBenefitsListingRedirect {...props} /></Suspense>);
globalAny.SavingsBenefitsListing = (props) => (<Suspense fallback={<LoadingIndicator />}><SavingsBenefitsListing {...props} /></Suspense>);
globalAny.ChaptersBlogListingRedirect = (props) => (<Suspense fallback={<LoadingIndicator />}><ChaptersBlogListingRedirect {...props} /></Suspense>);
globalAny.FinLitListing = (props) => (<Suspense fallback={<LoadingIndicator />}><FinLitListing {...props} /></Suspense>);
globalAny.NewsListing = (props) => (<Suspense fallback={<LoadingIndicator />}><NewsListing {...props} /></Suspense>);
globalAny.NVKBListing = (props) => (<Suspense fallback={<LoadingIndicator />}><NVKBListing {...props} /></Suspense>);
globalAny.NVKBBrowse = (props) => (<Suspense fallback={<LoadingIndicator />}><NVKBBrowse {...props} /></Suspense>);
globalAny.FeaturedChapter = (props) => (<Suspense fallback={<LoadingIndicator />}><FeaturedChapter {...props} /></Suspense>);;
globalAny.Spotlight = (props) => (<Suspense fallback={<LoadingIndicator />}><Spotlight {...props} /></Suspense>);;
globalAny.FormComponent = (props) => (<Suspense fallback={<LoadingIndicator />}><FormComponent {...props} /></Suspense>);;
globalAny.SiteSearch = (props) => (<Suspense fallback={<LoadingIndicator />}><SiteSearch {...props} /></Suspense>);;

export default global;